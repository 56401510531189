import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import BackgroundImage from 'gatsby-background-image'
import CountUp from 'react-countup'

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main
  },
  wrapper: () => ({
    padding: '32px 0 68px',
    backgroundSize: 'cover',
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '32px 0 40px',
      '& h3': {
        padding: '0 24px',
        marginBottom: '24px'
      },
      '& h1': {
        marginBottom: '5px'
      }
    }
  })
}))

const Stats = ({ image }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <BackgroundImage Tag="section" fluid={image.childImageSharp.fluid}>
        <Box className={classes.wrapper} display="flex" flexDirection="column">
          <Container maxWidth="xl">
            <Typography variant="h3" gutterBottom>
              Empowering the Coach and Golfer Journey
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Typography variant="h1" gutterBottom>
                    <CountUp start={0} end={3000} enableScrollSpy />+
                  </Typography>
                  <Typography variant="subtitle2">Golf Coaches</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Typography variant="h1" gutterBottom>
                    <CountUp start={0} end={23000} enableScrollSpy />+
                  </Typography>
                  <Typography variant="subtitle2">
                    Training Course Completions
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Typography variant="h1" gutterBottom>
                    <CountUp start={0} end={230000} enableScrollSpy />+
                  </Typography>
                  <Typography variant="subtitle2">
                    Private & Group Lessons Booked
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Typography variant="h1" gutterBottom>
                    <CountUp start={0} end={450000} enableScrollSpy />+
                  </Typography>
                  <Typography variant="subtitle2">
                    Golfers & Families Served
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </BackgroundImage>
    </Box>
  )
}

export default Stats
