import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import MenuIcon from '@material-ui/icons/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import LogoImg from '../images/logo_PGACOACH.svg'
import { headlineFont, eyebrowFont } from '../mui-theme'
import CloseIcon from '@material-ui/icons/Close'
import CustomLink from './custom-link'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Popover from '@material-ui/core/Popover'
import MenuList from '@material-ui/core/MenuList'

export const useStyles = makeStyles(theme => ({
  root: () => ({}),
  navItem: {
    fontFamily: headlineFont,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '10px',
      paddingRight: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.secondary.dark,
      padding: '20px 0',
      height: '54px',
      width: '100%',
      borderBottom: '1px solid #D8D8D8',
      fontSize: '16px',
      justifyContent: 'start'
    },
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:after': {
        content: '""',
        position: 'absolute',
        left: '0px',
        right: '0px',
        bottom: '-12px',
        borderBottom: '6px solid',
        borderColor: theme.palette.primary.main
      },
      [theme.breakpoints.down('sm')]: {
        color: theme.palette.secondary.dark,
        textDecoration: 'none',
        '&:after': {
          display: 'none'
        }
      }
    }
  },
  appBar: {
    backgroundColor: theme.palette.secondary.main,
    height: '64px',
    padding: '12px 0',
    '& img': {
      height: '29px',
      wight: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      height: '45px',
      padding: '7px 0'
    }
  },
  mobileSidebar: {
    left: '0',
    right: '0',
    bottom: `calc(-1 * 100vh + 100% + 0px)`,
    height: `calc(100vh - 100% - 0px)`,
    width: '100vw',
    zIndex: '1',
    overflow: 'auto',
    position: 'absolute',
    backgroundColor: '#F7F7F7',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transform: 'translateX(-100vw)',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    '&.active': {
      transform: 'none'
    }
  },
  mobileSidebarBottom: {
    gap: '12px',
    padding: '24px 0 100px',
    borderTop: '1px solid #D8D8D8'
  },
  menuButton: {
    padding: '0',
    fontSize: '12px',
    fontFamily: eyebrowFont,
    letterSpacing: '1.5px',
    fontWeight: '500'
  },
  button: {
    borderColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.dark,
    '&:hover, &:focus': {
      borderColor: theme.palette.secondary.dark,
      color: `${theme.palette.secondary.dark}!important`
    }
  },
  selectPaper: {
    transform: 'translateY(50px)'
  },
  menuItem: {
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: 'rgba(0, 0, 0, 0.87)'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  dropdown: {
    borderRadius: '4px',
    marginTop: '4px',
    width: '150px'
  }
}))

const NavItems = ({ handleClose }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  const handleClickScroll = props => {
    if (isMobile) handleClose()
    const isRoot = window.location.pathname === '/'

    !isRoot && window.open(`/#${props}`, '_self')

    const element = document.getElementById(props)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
      <Button
        variant="text"
        className={classes.navItem}
        onClick={() => handleClickScroll('get-started')}
      >
        Getting Started
      </Button>
      <Button
        variant="text"
        className={classes.navItem}
        onClick={() => handleClickScroll('download')}
      >
        Download
      </Button>
      <Button
        variant="text"
        className={classes.navItem}
        component={CustomLink}
        href="/training"
      >
        Training Center
      </Button>
      <Button
        variant="text"
        className={classes.navItem}
        component={CustomLink}
        href="https://www.pga.com/coach?utm_campaign=coachlp"
        target="_blank"
        rel="noreferrer"
      >
        Find a Coach
      </Button>
    </Box>
  )
}
const Header = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const [open, setOpen] = useState(false)

  const handleMenu = () => {
    setOpen(true)
    document.body.style.overflow = 'hidden'
  }

  const handleClose = () => {
    setOpen(false)
    document.body.style.overflow = 'visible'
  }

  const handleClickScrollToTop = props => {
    const isRoot = window.location.pathname === '/'

    if (isMobile) handleClose()

    if (isRoot) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    } else {
      window.location.href = '/'
    }
  }

  return (
    <>
      <AppBar position="fixed" id="header">
        <Box className={classes.appBar}>
          <Container maxWidth="xl">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <a onClick={() => handleClickScrollToTop()} href="#header">
                <img src={LogoImg} />
              </a>
              {!isMobile && (
                <>
                  <Box display={{ xs: 'none', md: 'flex' }}>
                    <NavItems />
                  </Box>
                  <Box display={{ xs: 'none', md: 'flex' }}>
                    <AccountDropdown />
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      component={CustomLink}
                      href="https://calendar.app.google/3BZZyUaFYvFaMmdu8"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Schedule Demo
                    </Button>
                  </Box>
                </>
              )}
              {isMobile && (
                <div>
                  {!open ? (
                    <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                      className={classes.menuButton}
                    >
                      MENU
                      <MenuIcon style={{ marginLeft: '8px' }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleClose}
                      color="inherit"
                      className={classes.menuButton}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <Box
                    className={`${classes.mobileSidebar} ${
                      open ? 'active' : null
                    }`}
                  >
                    <NavItems handleClose={handleClose} />
                    <Box
                      display="flex"
                      flexDirection="column"
                      className={classes.mobileSidebarBottom}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        component={CustomLink}
                        href="https://my.pga.com/pga-coach?utm_campaign=coachlp&utm_source=hdr"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Launch PGA Coach
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        className={classes.button}
                        component={CustomLink}
                        href="https://pgacoach.lightspeedvt.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Access Training Center
                      </Button>
                      <Button
                        variant="text"
                        color="secondary"
                        fullWidth
                        component={CustomLink}
                        href="https://calendar.app.google/3BZZyUaFYvFaMmdu8"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Schedule Demo
                      </Button>
                    </Box>
                  </Box>
                </div>
              )}
            </Box>
          </Container>
        </Box>
      </AppBar>
    </>
  )
}

export function AccountDropdown() {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const toggleAccountMenu = event => {
    setAnchorEl(el => (el ? null : event.currentTarget))
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        endIcon={<ArrowDropDownIcon />}
        onClick={toggleAccountMenu}
      >
        Log In
      </Button>
      <Popover
        id="account-dropdown-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={closeMenu}
        classes={{ paper: classes.dropdown }}
      >
        <MenuList autoFocusItem>
          <MenuItem
            component={'a'}
            href="https://my.pga.com/pga-coach?utm_campaign=coachlp&utm_source=hdr"
            target="_blank"
            rel="noreferrer"
            onClick={closeMenu}
            classes={{ root: classes.menuItem }}
          >
            PGA Coach
          </MenuItem>
          <MenuItem
            component={'a'}
            target="_blank"
            rel="noreferrer"
            href="https://pgacoach.lightspeedvt.com"
            onClick={closeMenu}
            classes={{ root: classes.menuItem }}
          >
            Training Center
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  )
}

export default Header
